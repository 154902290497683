@import "./styles/_constants.scss";

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $STANDART-FONT, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input, textarea, button {
  font-family: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

html, body {
  overflow: hidden;
}

a {
  text-decoration: none;
}

* {
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb {
    background: $GRAY-SCROLLBAR;
    border-radius: 10px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $GRAY-SCROLLBAR-HOVER;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
}
