// * fonts
$STANDART-FONT: 'Open Sans', sans-serif;
$GOOGLE-SANS: 'Google Sans', sans-serif;
$PRINT-FONT: 'Inter', sans-serif;

// * colors
$WHITE: #ffffff;
$LINE-INPUT: rgba(0, 0, 0, 0.05);
$RED: #ff3131;
$PRIMARY: #6cc24a;
$PRIMARY-LIGHT: #eaffe2;
$YELLOW: #f4c61f;
$ACTIVE-ORDER-HOVER: #fffcf0;
$GRAY-LIGHT-ORDER: #fcfcfc;
$GRAY-LIGHT: #f3f3f3;
$GRAY-DROPDOWN: #fafafa;
$GRAY-ACTIVE: #e6e6e6;
$GRAY-DARK: #96a7af;
$GRAY-DARK-TEXT: #6b777c;
$BLACK: #121212;
$BLACK-NATIVE: #000000;
$ICON-ACTIVE: #6cc24a;
$ICON-DISABLE: #96a7af;
$ICON-BLANC: #ffffff;
$PRIMARY-HOVER: #67d03e;
$DANGEROUS-HOVER: #ff4d4d;
$DANGEROUS-LIGHT: #fff9f9;
$ARTICLE-NEW: #7b61ff;
$NOT-FOUND-TEXT: #ff9800;
$GRAY-SCROLLBAR: #888;
$GRAY-SCROLLBAR-HOVER: #555;

$PRINT-TABLE-HEADER: #dbdbdb;
$PRINT-TABLE-ROW: #f3f3f3;
$PRINT-TABLE-BORDER: #c9c9c9;
$UNIT-CATERING: #4dab28;
$UNIT-HOTEL: #d55816;
$UNIT-CAFETERIA: #45a7c6;

// * font-weight
$FW-THIN: 100;
$FW-EXTRA_LIGHT: 200;
$FW-LIGHT: 300;
$FW-NORMAL: 400;
$FW-MEDIUM: 500;
$FW-SEMI_BOLD: 600;
$FW-BOLD: 700;
$FW-EXTRA_BOLD: 800;
$FW-HEAVY: 900;

$LAYOUT_INDENT: 28px;
$HEIGHT-HEADER: 75px;
$HEIGHT-MOBILE-MENU: 60px;
